import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import cloudinaryUpload from '../../../../server/uploads.js';
import cloudinaryDelete from '../../../../server/delete.js';
import * as yup from 'yup';

const schema = yup.object({
  image_1: yup.string().required('Không được bỏ trống'),
  image_2: yup.string().required('Không được bỏ trống'),
  image_3: yup.string().required('Không được bỏ trống'),
  image_4: yup.string().required('Không được bỏ trống'),
  image_5: yup.string().required('Không được bỏ trống'),
  image_6: yup.string().required('Không được bỏ trống'),
});

const FormThoughtImage = ({ image, onRefresh }) => {
  const [uploadOptions, setUploadOptions] = useState({
    image_1: 'file',
    image_2: 'file',
    image_3: 'file',
    image_4: 'file',
    image_5: 'file',
    image_6: 'file',
  });
  const [images, setImages] = useState({
    image_1: {
      url: image?.image_1,
      publicId: image?.id_image_1,
      isUploaded: false,
    },
    image_2: {
      url: image?.image_2,
      publicId: image?.id_image_2,
      isUploaded: false,
    },
    image_3: {
      url: image?.image_3,
      publicId: image?.id_image_3,
      isUploaded: false,
    },
    image_4: {
      url: image?.image_4,
      publicId: image?.id_image_4,
      isUploaded: false,
    },
    image_5: {
      url: image?.image_5,
      publicId: image?.id_image_5,
      isUploaded: false,
    },
    image_6: {
      url: image?.image_6,
      publicId: image?.id_image_6,
      isUploaded: false,
    },
  });
  const [isLoadeds, setIsLoadeds] = useState({
    image_1: {
      isLoaded: false,
    },
    image_2: {
      isLoaded: false,
    },
    image_3: {
      isLoaded: false,
    },
    image_4: {
      isLoaded: false,
    },
    image_5: {
      isLoaded: false,
    },
    image_6: {
      isLoaded: false,
    },
  });

  const form = useForm({
    defaultValues: {
      image_1: image.image_1,
      id_image_1: image.id_image_1,
      image_2: image.image_2,
      id_image_2: image.id_image_2,
      image_3: image.image_3,
      id_image_3: image.id_image_3,
      image_4: image.image_4,
      id_image_4: image.id_image_4,
      image_5: image.image_5,
      id_image_5: image.id_image_5,
      image_6: image.image_6,
      id_image_6: image.id_image_6,
    },
    resolver: yupResolver(schema),
  });
  const { handleSubmit, reset, formState, control, setValue } = form;
  const { errors, isSubmitSuccessful } = formState;

  const fetchBanner = useCallback(async () => {
    try {
      const res = await fetch(`https://api.thechuck.vn/thought/get-imgae`);
      const text = await res.text();
      const data = text ? JSON.parse(text) : {};
      setValue('image_1', data.image_1);
      setValue('id_image_1', data.id_image_1);
      setValue('image_2', data.image_2);
      setValue('id_image_2', data.id_image_2);
      setValue('image_3', data.image_3);
      setValue('id_image_3', data.id_image_3);
      setValue('image_4', data.image_4);
      setValue('id_image_4', data.id_image_4);
      setValue('image_5', data.image_5);
      setValue('id_image_5', data.id_image_5);
      setValue('image_6', data.image_6);
      setValue('id_image_6', data.id_image_6);
    } catch (error) {
      console.error('Error fetching project tag details:', error);
    }
  }, [setValue]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      onRefresh();
      fetchBanner();
      reset({ ...image });
    }
  }, [isSubmitSuccessful, fetchBanner, onRefresh, reset, image]);

  const handleFileUploadItem = async (index, file) => {
    const newIsLoadImages = { ...isLoadeds };
    newIsLoadImages[`image_${index + 1}`] = { isLoaded: true };
    setIsLoadeds(newIsLoadImages);
    const fileData = new FormData();
    fileData.append('file', file);
    try {
      const res = await cloudinaryUpload(fileData);
      const newImages = { ...images }; // copy the images object
      newImages[`image_${index + 1}`] = {
        url: res.secure_url,
        publicId: res.publicId,
        isUploaded: true,
      };
      setImages(newImages);
      setValue(`image_${index + 1}`, res.secure_url);
      setValue(`id_image_${index + 1}`, res.publicId);
    } catch (err) {
      console.error('Upload Error:', err);
    }
  };

  const handleDeleteImageItem = async (index) => {
    const publicId = images[`image_${index + 1}`].publicId;
    if (!publicId) {
      alert('No image to delete');
      return;
    }
    try {
      await cloudinaryDelete(publicId);
      const newImages = { ...images }; // copy the images object
      newImages[`image_${index + 1}`] = {
        url: '',
        publicId: '',
        isUploaded: false,
      };
      setImages(newImages);
      setValue(`image_${index + 1}`, '');
      const newIsLoadImages = { ...isLoadeds };
      newIsLoadImages[`image_${index + 1}`] = { isLoaded: false };
      setIsLoadeds(newIsLoadImages);
    } catch (err) {
      console.error('Deletion Error:', err.message);
      alert('Failed to delete image');
    }
  };

  const handleUploadOptionChanges = (index, e) => {
    const newOptions = { ...uploadOptions };
    newOptions[`image_${index + 1}`] = e.target.value;
    setUploadOptions(newOptions);
  };
  const extractSrcFromIframe = (iframeString) => {
    const srcMatch = iframeString.match(
      /src\s*=\s*"([^"]+)"|src\s*=\s*'([^']+)'/
    );
    return srcMatch ? srcMatch[1] || srcMatch[2] : null;
  };

  const handleInputChangeItem = (index, e) => {
    const embedCode = e.target.value;
    const url = extractSrcFromIframe(embedCode);
    const newImages = { ...images }; // copy the existing images state

    newImages[`image_${index + 1}`] = {
      ...images[`image_${index + 1}`],
      url: url,
      isUploaded: true, // assume a new URL means a new upload
    };
    setImages(newImages);
    setValue(`image_${index + 1}`, url);
  };
  const checkURL = (url) => {
    return /\.(jpeg|jpg|gif|png)$/i.test(url);
  };
  const isValidYoutubeEmbedUrl = (url) => {
    const pattern = /^https:\/\/www\.youtube\.com\/embed\/.+/i;
    return pattern.test(url);
  };

  const renderImageUpload = (index) => (
    <div className="form-project-flex">
      <div className="form-project-flex-item">
        <label>{`Hình ảnh ${index + 1}`}</label>
        <select
          value={uploadOptions[`image_${index + 1}`]}
          onChange={(e) => handleUploadOptionChanges(index, e)}>
          <option value="file">Tải lên từ máy</option>
          <option value="url">Nhập link từ YouTube</option>
        </select>
        {uploadOptions[`image_${index + 1}`] === 'file' ? (
          <input
            type="file"
            onChange={(e) => handleFileUploadItem(index, e.target.files[0])}
            accept="image/*,video/*"
          />
        ) : (
          <input
            type="text"
            placeholder="Nhập mã nhúng từ YouTube"
            onChange={(e) => handleInputChangeItem(index, e)}
          />
        )}
        {errors[`image_${index + 1}`] && (
          <p className="error">{errors[`image_${index + 1}`].message}</p>
        )}
      </div>
      {isLoadeds[`image_${index + 1}`].isLoaded ? (
        images[`image_${index + 1}`].isUploaded ? (
          <div className="form-project-flex-img">
            {checkURL(images[`image_${index + 1}`].url) ? (
              <img
                src={images[`image_${index + 1}`].url}
                title="Uploaded Image"
                alt="Uploaded"
              />
            ) : (
              <iframe
                src={images[`image_${index + 1}`].url}
                title="Uploaded Video"
                alt="Uploaded"
              />
            )}
            <p onClick={() => handleDeleteImageItem(index)}>Xóa</p>
          </div>
        ) : (
          <p className="project-loading">Loading ...</p>
        )
      ) : (
        <div className="form-project-flex-img">
          {checkURL(image[`image_${index + 1}`]) ? (
            <img
              src={image[`image_${index + 1}`]}
              title="Uploaded Image"
              alt="Uploaded"
            />
          ) : isValidYoutubeEmbedUrl(image[`image_${index + 1}`]) ? (
            <iframe
              src={image[`image_${index + 1}`]}
              title="Uploaded Video"
              alt="Uploaded"
            />
          ) : (
            <iframe
              src={image[`image_${index + 1}`]}
              title="Uploaded Video"
              alt="Uploaded"
            />
          )}
        </div>
      )}
    </div>
  );

  const handleSubmitProject = async (data) => {
    function convertToUnicode(input) {
      return input.replace(
        /([\uD800-\uDBFF][\uDC00-\uDFFF])/g,
        function (char) {
          return '&#x' + char.codePointAt(0).toString(16) + ';';
        }
      );
    }
    function convertObjectToUnicode(obj) {
      for (let key in obj) {
        if (typeof obj[key] === 'object') {
          convertObjectToUnicode(obj[key]); // Nếu phần tử là một đối tượng, gọi đệ quy
        } else if (typeof obj[key] === 'string') {
          obj[key] = convertToUnicode(obj[key]); // Nếu phần tử là một chuỗi, chuyển đổi thành Unicode
        }
      }
      return obj;
    }

    const convertedData = convertObjectToUnicode(data);

    try {
      const url = `https://api.thechuck.vn/thought/update-image`;
      const opt = {
        method: 'put',
        body: JSON.stringify(convertedData),
        headers: { 'Content-Type': 'application/json' },
      };
      const res = await fetch(url, opt);
      const reponseData = await res.json();
      alert('Xác nhận thành công,', reponseData);
    } catch (error) {
      console.error('Lỗi khi cập nhật sản phẩm: ', error);
    }
  };

  return (
    <div className="form-project">
      <form
        onSubmit={handleSubmit(handleSubmitProject)}
        noValidate>
        {[0, 1, 2, 3, 4, 5].map((index) => (
          <div
            className="form-project-item"
            key={index}>
            {renderImageUpload(index)}
          </div>
        ))}
        <div className="form-project-item">
          <button>Xác nhận</button>
        </div>
      </form>
      <DevTool control={control} />
    </div>
  );
};

export default FormThoughtImage;
